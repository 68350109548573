import React from 'react'
import { Card, CardMedia, CardContent, Typography } from '@material-ui/core'
import moment from 'moment'
import firebase from 'firebase'
import UserAvatar, { UserName } from './Avatar'
import { makeStyles } from '@material-ui/styles'
import PlaceHolderImage from '../assets/placeholder.svg'
import { useStore } from '../hooks/useStore'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
  card: {
    border: '1px solid rgba(37, 48, 43, 0.4)',
    boxShadow: 'none'
  },
  cardContent: {
    display: 'flex'
  }
})

const removeComment = commentId => {
  return firebase
    .firestore()
    .collection('comments')
    .doc(commentId)
    .delete()
}

export default function CommentCard({
  authorId,
  commentText,
  dateCreated,
  url,
  domain,
  imageId,
  location,
  objectID,
  ...rest
}) {
  const { getCardCache, cardCache, auth } = useStore()
  const classes = useStyles()

  let image = null
  if (imageId) {
    getCardCache(imageId)
    image = cardCache[imageId]
  }

  return (
    <a href={url} target="_blank" style={{ textDecoration: 'none' }}>
      <Card className={classes.card}>
        <CardMedia
          style={{ height: 250, backgroundPosition: 'top center' }}
          image={image && image.url ? image.url : PlaceHolderImage}
          title={url}
        />
        <CardContent style={{ borderTop: '1px solid rgba(37, 48, 43, 0.4)' }}>
          <div className={classes.cardContent}>
            <div style={{ marginRight: 10 }}>
              <UserAvatar uid={authorId} />
              {auth && authorId === auth.uid && (
                <p
                  style={{
                    margin: '5px auto',
                    background: '#000',
                    color: '#fff',
                    padding: '0 5px 2px 5px',
                    fontSize: 10,
                    borderRadius: 5,
                    textAlign: 'center'
                  }}>
                  You
                </p>
              )}
            </div>
            <div>
              <UserName uid={authorId} location={location} />
              <Typography style={{ color: 'rgba(37, 48, 43, .8)' }} variant="subtitle1">
                {commentText}
              </Typography>
              <Typography style={{ color: 'rgba(37, 48, 43, .4)' }} variant="caption">
                {moment(dateCreated).fromNow()} -{' '}
                <a
                  style={{
                    textDecoration: 'none',
                    color: 'rgba(37, 48, 43, .6)'
                  }}
                  href={`https://${domain}`}
                  target="_blank">
                  {domain}
                </a>
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
    </a>
  )
}
