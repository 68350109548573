import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { GOOGLE_AUTH_CONTROLLER, loginHandler } from '../hooks/useAuth'
import { useStore } from '../hooks/useStore'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { bookMarkCode } from '../assets/bookmarkCode'
import { ReactComponent as HeaderLogo } from '../assets/header.svg'
import { IoLogoGoogle, IoIosLogOut } from 'react-icons/io'
import UserAvatar from '../components/Avatar'
import { ReactComponent as Arrow } from '../assets/arrow.svg'
import { connectSearchBox } from 'react-instantsearch-dom'
import { useSearch } from '../hooks/useSearch'

const StyledBox = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1em'
  }
})(Box)

const LoginBox = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '2em',
    marginTop: '1em'
  }
})(Box)

const Header = () => {
  const { auth } = useStore()
  const { searchValue, setSearchValue } = useSearch()
  const [isMouseoverButton, setMouseoverButton] = useState(false)
  const [dragFontSize, setDragFontSize] = useState(16)
  const [value, setValue] = useState('')
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1

  return (
    <div>
      <LoginBox>
        {auth ? (
          <>
            <UserAvatar uid={auth.uid} />
            <Button onClick={loginHandler} style={{ marginLeft: 15 }}>
              <IoIosLogOut style={{ marginRight: 10 }} /> Logout
            </Button>
          </>
        ) : (
          <Button variant="outlined" color="primary" onClick={() => loginHandler(GOOGLE_AUTH_CONTROLLER)}>
            <IoLogoGoogle style={{ marginRight: 10 }} /> Login
          </Button>
        )}
      </LoginBox>
      <StyledBox style={{ position: 'relative', paddingTop: 50 }}>
        {!isFirefox && (
          <Arrow
            style={{
              position: 'absolute',
              top: '-65px',
              left: '-50px',
              width: '70%',
              height: '60%'
            }}
          />
        )}
        <HeaderLogo style={{ marginLeft: '100px' }} />
        <p
          style={{
            fontFamily: 'roboto mono',
            fontSize: 20,
            marginTop: 25,
            marginBottom: 45
          }}>
          the margins of the internet
        </p>
        <a
          href={`javascript:(${encodeURIComponent(bookMarkCode)})`}
          name="Add a Comment"
          style={{ textDecoration: 'none' }}>
          <Button
            onClick={e => {
              e.preventDefault()
              setDragFontSize(dragFontSize == 16 ? 24 : dragFontSize + 2)
            }}
            size="large"
            color="primary"
            variant="contained"
            onMouseDown={() => {
              setMouseoverButton(true)
            }}
            onMouseLeave={() => {
              setMouseoverButton(false)
            }}>
            {isMouseoverButton || isFirefox ? 'Browser Thoughts' : 'Drag to Bookmarks'}
          </Button>
        </a>
        {isFirefox ? (
          <p style={{ marginTop: 20, marginBottom: 100, textAlign: 'center' }}>
            Simply right click this button, and choose "Bookmark Link" to get started. <br />
            Then, click the bookmark to open Browser Thoughts.
          </p>
        ) : (
          <p style={{ marginTop: 20, marginBottom: 100, textAlign: 'center' }}>
            Simply <span style={{ fontSize: dragFontSize }}>drag</span> this button to your Bookmarks Bar to get
            started. <br />
            Then, click the bookmark to open Browser Thoughts.
          </p>
        )}

        <div
          style={{
            width: '100%',
            height: 50,
            borderTop: '.5px grey solid',
            borderBottom: '.5px grey solid',
            marginBottom: 25
          }}>
          <div style={{ maxWidth: 1000, margin: '0 auto' }}>
            <input
              style={{
                width: '100%',
                height: 45,
                border: 'none',
                fontSize: '28px',
                outline: 'none'
              }}
              placeholder="Search url or comment"
              type="text"
              value={searchValue}
              onChange={e => {
                setSearchValue(e.target.value)
              }}
            />
          </div>
        </div>
      </StyledBox>
    </div>
  )
}

export default Header
