import React, { useState } from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import HomePage from './views/Home'
import Widget from './views/Widget'
import Site from './views/Site'

export default function App() {
  return (
    <Switch>
      <Route exact path="/" render={props => <HomePage {...props} />} />
      <Route
        exact
        path="/widget/:sourceUrl/"
        render={props => <Widget {...props} />}
      />
      <Route
        exact
        path="/site/:sourceUrl/:error?"
        render={props => <Site {...props} />}
      />
    </Switch>
  )
}
