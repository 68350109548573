import React from 'react'
import { CircularProgress, Avatar } from '@material-ui/core'
import { useStore } from '../hooks/useStore'
import Skeleton from '@material-ui/lab/Skeleton'

export default function UserAvatar({ uid }) {
  const { getProfile, profileCache } = useStore()
  let profile = null
  if (uid) {
    getProfile(uid)
    profile = profileCache[uid]
  }

  if (profile && profile.loading) {
    return (
      <Avatar>
        <CircularProgress />
      </Avatar>
    )
  }
  if (profile && profile.picture) {
    return <Avatar alt={profile.name} src={profile.picture} />
  }
  return null
}

export function UserName({ uid, location }) {
  const { getProfile, profileCache } = useStore()
  let profile = null
  if (uid) {
    getProfile(uid)
    profile = profileCache[uid]
  }

  var profileNameCopy = (' ' + profile.name).slice(1)

  if (profileNameCopy != 'undefined') {
    return (
      <span
        style={{
          color: '#25302B',
          fontFamily: 'roboto mono',
          fontWeight: 'bold',
          letterSpacing: '-1px',
        }}>
        {profileNameCopy.split(' ')[0]} {location && ' - ' + location}
      </span>
    )
  }
  return <Skeleton width="75%" height="25px" />
}
