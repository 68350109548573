import React, { useEffect, useState } from 'react'
import { useStore } from '../hooks/useStore'
import firebase from 'firebase'
import Header from '../components/Header'
import SideMenu from '../components/SideMenu'
import loadingGif from '../assets/loading.gif'
import CommentCard from '../components/CommentCard'
import SiteCard from '../components/SiteCard'
import { Grid, Button } from '@material-ui/core'

import algoliasearch from 'algoliasearch/lite'
import SearchProvider, { useSearch } from '../hooks/useSearch'

const searchClient = algoliasearch('IPLDPXJHM6', '5951569197cd35ed5332bea06cd08e43')

export default function Home() {
  const [comments, setComments] = useState([])
  const [currentTab, setCurrentTab] = useState('comment')

  const store = useStore()
  const [animate, setAnimate] = useState(true)

  useEffect(() => {
    firebase
      .firestore()
      .collection('comments')
      .orderBy('dateCreated', 'desc')
      .limit(21)
      .get()
      .then(res => {
        let commentsQuery = []
        res.docs.forEach(doc => {
          commentsQuery.push({ id: doc.id, ...doc.data() })
        })
        setComments(commentsQuery)
      })
  }, [])

  setTimeout(() => setAnimate(false), 1000)
  if (store.isLoading || animate) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <img style={{ width: '400px' }} src={loadingGif}></img>
      </div>
    )
  }
  return (
    <SearchProvider>
      <div>
        <div id="hackbit-vote-widget">
          <div class="hb-vote-widget">
            <a class="hb-link" href="http://www.nodeknockout.com/entries/106-nicer-friends" target="_blank">
              Built for{' '}
              <img
                height="30"
                src="https://hackbit-nko2019.s3.amazonaws.com/images/widget-logo.png"
                alt="Widget logo"
              />
            </a>

            <a class="hb-vote-btn" href="http://www.nodeknockout.com/entries/106-nicer-friends/vote">
              Vote for us
            </a>
          </div>
        </div>
        <Header />
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <SideMenu currentTab={currentTab} setCurrentTab={setCurrentTab} style={{ width: '19%' }} />
          <div
            style={{
              width: '75%',
              display: 'flex',
              alignSelf: 'flex-end',
              flexWrap: 'wrap'
            }}>
            <Grid container spacing={2}>
              <Hits />
            </Grid>
          </div>
        </div>
      </div>
    </SearchProvider>
  )
}

function SearchResult({ objectType, ...rest }) {
  if (objectType === 'comment') {
    return (
      <Grid xs="12" md="6" lg="4" item>
        <CommentCard {...rest} />
      </Grid>
    )
  }
  if (objectType === 'site') {
    return (
      <Grid xs="12" md="6" lg="4" item>
        <SiteCard {...rest} />
      </Grid>
    )
  }
  return null
}

function Hits() {
  const { hits } = useSearch()
  if (hits.length < 1) {
    return (
      <div style={{ width: '100%', textAlign: 'center', margin: '250px 0' }}>
        <h3>No Results Found</h3>
      </div>
    )
  }
  return (
    <>
      {hits.map(hit => (
        <SearchResult {...hit} />
      ))}
      {/* <div style={{ width: '100%', textAlign: 'center', margin: '50px 0' }}>
        <Button
          color="secondary"
          variant="contained"
          disabled={!hasMore}
          onClick={refineNext}>
          {hasMore ? 'Load More' : 'Your All Done'}
        </Button>
      </div> */}
    </>
  )
}
