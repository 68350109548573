import React, { useEffect, createContext, useReducer, useContext, useState } from 'react'

import algoliasearch from 'algoliasearch'

export const searchContext = createContext()

export const useSearch = () => useContext(searchContext)

function SearchProvider({ children, history }) {
  let searchClient
  let searchIndex
  searchClient = algoliasearch('IPLDPXJHM6', '5951569197cd35ed5332bea06cd08e43')
  searchIndex = searchClient.initIndex('dev_browser-thoughts')

  const [currentPage, setCurrentPage] = useState(0)
  console.log(currentPage)
  const [searchResults, setSearchResults] = useState({})
  const [hits, setHits] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [facetValues, setFacetValues] = useState({})
  const [searchObjectType, setSearchObjectType] = useState(['objectType:comment'])
  useEffect(() => {
    search()
  }, [searchValue, searchObjectType])

  async function search() {
    const facetsSearch = await searchIndex.search({
      query: searchValue,
      facets: ['objectType']
    })
    const newResultsState = await searchIndex.search({
      query: searchValue,
      facets: ['objectType', 'authorId', 'domain'],
      facetFilters: [searchObjectType]
    })
    setFacetValues({
      ...facetsSearch.facets.objectType,
      total: facetsSearch.nbHits
    })
    const { hits, ...searchData } = newResultsState
    setHits(hits)
    setSearchResults(searchData)
    setCurrentPage(newResultsState.page)
  }

  async function loadMore() {
    if (currentPage < searchResults.nbPages) {
      setCurrentPage(currentPage + 1)
      const newResultsState = await searchIndex.search({
        query: searchValue,
        facets: ['objectType'],
        facetFilters: `objectType:${searchObjectType}`,
        page: currentPage + 1
      })
      setHits([...hits, ...newResultsState.hits])
    }
  }

  return (
    <searchContext.Provider
      value={{
        searchValue,
        setSearchValue,
        facetValues,
        setSearchObjectType,
        hits,
        loadMore,
        currentPage
      }}>
      {children}
    </searchContext.Provider>
  )
}

export default SearchProvider
