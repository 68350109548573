import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  useReducer,
} from 'react'

import firebase from 'firebase'

export const storeContext = createContext({})

export const useStore = () => useContext(storeContext)

export default function Store({ children }) {
  const [isLoading, setIsLoading] = useState(true)
  const [auth, setAuth] = useState(null)
  const [favorites, setFavorites] = useState([])

  const [profileCache, setProfileCache] = useReducer((state, newState) => {
    return { ...state, ...newState }
  }, {})

  const [cardCache, setCardCache] = useReducer((state, newState) => {
    return { ...state, ...newState }
  }, {})

  const [siteCache, setSiteCache] = useReducer((state, newState) => {
    return { ...state, ...newState }
  }, {})

  // Check for auth change
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(auth => {
      setIsLoading(false)
      setAuth(auth)
    })
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (auth) {
      const unsubscribe = firebase
        .firestore()
        .collection('users')
        .doc(auth.uid)
        .collection('favorites')
        .onSnapshot(res => {
          let favQuery = []
          res.docs.forEach(domain => {
            favQuery.push(domain.id)
          })
          setFavorites(favQuery)
        })
      return () => unsubscribe()
    }
  }, [auth])

  function getProfile(uid) {
    if (!profileCache[uid]) {
      profileCache[uid] = true
      setProfileCache({
        [uid]: {
          id: uid,
          loading: true,
        },
      })
      firebase
        .firestore()
        .collection('profiles')
        .doc(uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            const data = doc.data()
            setProfileCache({
              [uid]: {
                id: doc.id,
                exists: true,
                ...data,
              },
            })
          } else {
            setProfileCache({
              [uid]: {
                id: doc.id,
                exists: false,
              },
            })
          }
        })
    }
  }

  function getCardCache(id) {
    if (!cardCache[id]) {
      cardCache[id] = true
      setCardCache({
        [id]: {
          id,
          loading: true,
        },
      })
      firebase
        .storage()
        .ref()
        .child(`commentsImage/${id}.png`)
        .getDownloadURL()
        .then(res => {
          setCardCache({
            [id]: {
              id,
              loading: false,
              url: res,
            },
          })
        })
    }
  }

  function getSiteCache(domain) {
    if (!siteCache[domain]) {
      cardCache[domain] = true
      setSiteCache({
        [domain]: {
          domain,
          loading: true,
        },
      })
      firebase
        .storage()
        .ref()
        .child(`site/${domain}.png`)
        .getDownloadURL()
        .then(res => {
          setSiteCache({
            [domain]: {
              domain,
              loading: false,
              url: res,
            },
          })
        })
    }
  }

  function favoriteDomain(domain) {
    return firebase
      .firestore()
      .collection('users')
      .doc(auth.uid)
      .collection('favorites')
      .doc(domain)
      .set({
        domain,
        followDate: Date.now(),
      })
  }

  function removeFavoriteDomain(domain) {
    return firebase
      .firestore()
      .collection('users')
      .doc(auth.uid)
      .collection('favorites')
      .doc(domain)
      .delete()
  }

  return (
    <storeContext.Provider
      value={{
        isLoading,
        auth,
        getProfile,
        profileCache,
        getCardCache,
        cardCache,
        getSiteCache,
        siteCache,
        favoriteDomain,
        removeFavoriteDomain,
        favorites,
      }}>
      {children}
    </storeContext.Provider>
  )
}
