import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useStore } from '../hooks/useStore'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import firebase from 'firebase'
import moment from 'moment'
import { FiSend } from 'react-icons/fi'
import UserAvatar, { UserName } from '../components/Avatar'
import loadingGif from '../assets/loading.gif'
import { ReactComponent as HeaderLogo } from '../assets/header.svg'
import parseDomain from 'parse-domain'
import { GOOGLE_AUTH_CONTROLLER, loginHandler } from '../hooks/useAuth'
import { IoLogoGoogle, IoIosLogOut } from 'react-icons/io'

let isFirstRender = true

const robotoMono = {
  fontFamily: 'roboto mono'
}

function getTld(url) {
  const data = parseDomain(url)
  return data.domain + '.' + data.tld
}

export default function Site() {
  const { isLoading, auth, favoriteDomain, removeFavoriteDomain, favorites } = useStore()
  const [url, setUrl] = useState('')
  const [comments, setComments] = useState({})
  const [backgroundImage, setbackGroundImage] = useState('')

  const { sourceUrl } = useParams()
  let unencodedUrl = decodeURIComponent(sourceUrl)
  if (getTld(unencodedUrl) != 'youtube.com') {
    unencodedUrl = unencodedUrl.split('?')[0]
  }
  console.log(unencodedUrl)
  const [commentText, setCommentText] = useState('')

  let locationString = ''
  const addComment = commentText => {
    if (commentText && commentText.length < 300) {
      const newComment = {
        dateCreated: Date.now(),
        commentText: commentText,
        authorId: auth.uid,
        url: unencodedUrl,
        domain: getTld(unencodedUrl),
        location: locationString
      }

      setCommentText('')

      return firebase
        .firestore()
        .collection('comments')
        .add(newComment)
        .then(docRef => {
          setComments({
            ...comments,
            [docRef.id]: newComment
          })
        })
    }
    setCommentText('')
  }

  const removeComment = commentId => {
    return firebase
      .firestore()
      .collection('comments')
      .doc(commentId)
      .delete()
  }

  const siteIsFavorited = tld => {
    return favorites.includes(tld)
  }

  useEffect(() => {
    firebase
      .storage()
      .ref()
      .child(`site/${getTld(unencodedUrl)}.png`)
      .getDownloadURL()
      .then(res => {
        setbackGroundImage(res)
      })
  }, [])

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('comments')
      .where('url', '==', unencodedUrl)
      .orderBy('dateCreated', 'desc')
      .limit(20)
      .onSnapshot(snap => {
        let newComments = {}
        snap.forEach(commentSnap => {
          newComments[commentSnap.id] = commentSnap.data()
        })

        setComments(newComments)
      })

    return () => unsubscribe()
  }, [])

  window.callback = data => {
    if (data.state) {
      locationString = data.state + ', ' + data.country_code
    } else {
      locationString = data.country_name
    }
  }

  var script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = 'https://geolocation-db.com/jsonp/0f761a30-fe14-11e9-b59f-e53803842572'
  var h = document.getElementsByTagName('script')[0]
  h.parentNode.insertBefore(script, h)

  const sortedComments = Object.keys(comments)
    .map(commentId => {
      return {
        ...comments[commentId],
        commentId: commentId
      }
    })
    .sort((a, b) => {
      return b.dateCreated - a.dateCreated
    })

  const [animate, setAnimate] = useState(true)
  const [slideIn, setSlideIn] = useState(false)
  if (isFirstRender) {
    setTimeout(() => setAnimate(false), 1400)
    setTimeout(() => setSlideIn(true), 1)
    isFirstRender = false
  }
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          width: '100%',
          height: '100vh',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          opacity: 0.4
        }}></div>
      <div
        className={slideIn ? 'slide-out' : ''}
        style={{
          background: '#fff',
          minHeight: 'calc(100% - 95px)',
          width: 350,
          top: 0,
          padding: 10,
          paddingBottom: 85,
          position: 'absolute',
          WebkitTransition: 'right 500ms ease-out',
          transition: 'right 500ms ease-out',
          right: -350
        }}>
        {(isLoading || animate) && (
          <div>
            <img style={{ maxWidth: '95%', marginTop: '-120px' }} src={loadingGif}></img>
          </div>
        )}
        <span style={{ display: isLoading || animate ? 'none' : 'inline' }}>
          <a href="https://browserthoughts.com/">
            <HeaderLogo style={{ maxWidth: '100%' }} />
          </a>
          <p style={{ ...robotoMono, marginTop: -15 }}>the margins of the internet</p>
          <div style={{ display: 'flex' }}>
            {auth &&
              (siteIsFavorited(getTld(unencodedUrl)) ? (
                <Button
                  onClick={() => removeFavoriteDomain(getTld(unencodedUrl))}
                  variant="outlined"
                  size="small"
                  style={robotoMono}>
                  Unfavorite Site
                </Button>
              ) : (
                <Button
                  onClick={() => favoriteDomain(getTld(unencodedUrl))}
                  variant="outlined"
                  size="small"
                  style={robotoMono}>
                  Favorite Site
                </Button>
              ))}
          </div>
          <div style={{ maxHeight: auth ? 'calc(100vh - 230px)' : 'calc(100vh - 170px)', overflow: 'auto' }}>
            {sortedComments.map(comment => {
              return (
                <div style={{ margin: '10px 0', padding: '5', display: 'flex' }} key={comment.commentId}>
                  <div>
                    <UserAvatar uid={comment.authorId} />
                    {auth && comment.authorId === auth.uid && (
                      <p
                        style={{
                          margin: '5px auto',
                          background: '#000',
                          color: '#fff',
                          padding: '0 5px 2px 5px',
                          fontSize: 10,
                          borderRadius: 5,
                          textAlign: 'center'
                        }}>
                        You
                      </p>
                    )}
                  </div>
                  <div style={{ margin: 5, marginLeft: 10 }}>
                    <UserName uid={comment.authorId} location={comment.location} />
                    <p style={{ margin: 0, color: '#25302B' }}>{comment.commentText}</p>
                    <p style={{ fontSize: 10, opacity: 0.4, marginBottom: 0 }}>
                      {moment(new Date(comment.dateCreated)).fromNow()}
                    </p>
                    {auth && comment.authorId === auth.uid && (
                      <ButtonGroup size="small" style={{ marginTop: 10 }}>
                        <Button onClick={() => removeComment(comment.commentId)} style={robotoMono}>
                          Delete
                        </Button>
                      </ButtonGroup>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          {auth ? (
            <div
              style={{
                position: 'absolute',
                bottom: 15,
                right: 15,
                width: 'calc(100% - 30px)'
              }}>
              <div
                style={{
                  display: 'flex'
                }}>
                <div style={{ marginTop: 5, marginRight: 10 }}>
                  <UserAvatar uid={auth.uid} />
                </div>
                <TextField
                  placeholder="Leave a note"
                  id="comment-field"
                  variant="outlined"
                  style={{ width: '100%' }}
                  value={commentText}
                  onChange={e => {
                    if (e.currentTarget.value.length <= 300) {
                      setCommentText(e.currentTarget.value)
                    }
                  }}
                  onKeyPress={ev => {
                    if (ev.key === 'Enter') {
                      addComment(commentText)
                    }
                  }}
                  name="commentText"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span
                          style={{
                            cursor: 'pointer',
                            padding: 5,
                            position: 'relative',
                            top: 2
                          }}
                          onClick={() => addComment(commentText)}>
                          <FiSend />
                        </span>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <p style={{ fontSize: 10, margin: '5px 0 0 5px' }}>
                {getTld(unencodedUrl)} blocks external scripts, so we had to open in a new page.
              </p>
            </div>
          ) : (
            <>
              <Button
                style={{ marginTop: 10 }}
                variant="outlined"
                color="primary"
                onClick={() => loginHandler(GOOGLE_AUTH_CONTROLLER)}>
                <IoLogoGoogle style={{ marginRight: 10 }} /> Login to comment
              </Button>
              <p style={{ fontSize: 10, margin: '5px 0 0 5px' }}>
                {getTld(unencodedUrl)} blocks external scripts, so we had to open in a new page.
              </p>
            </>
          )}
        </span>
      </div>
    </>
  )
}
