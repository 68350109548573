import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './firebase'
import Store from './hooks/useStore'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import { BrowserRouter as Router } from 'react-router-dom'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4B604F',
      contrastText: '#F8F8F8',
    },
    secondary: {
      main: '#D2695A',
      contrastText: '#fff',
    },
  },
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <Store>
        <App />
      </Store>
    </Router>
  </ThemeProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
