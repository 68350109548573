export const bookMarkCode = `(function() {
  const BOOKMARK_REDIRECT_STATUS = "on" != "off";
  const MAIN_URL = "https://browserthoughts.com/widget";
  const REDIRECT_URL = "https://browserthoughts.com/site";
  const loc = window.location;
  const doc = window.document;
  const scriptElem = doc.createElement("script");
  function redirect(error) {
    loc.href = REDIRECT_URL + "/" + encodeURIComponent(loc) + "/" + error;
  }
  function redirectWithError() {
    redirect("KMK1HAQY4T")
  }
  function init() {
    if (
      doc["readyState"] &&
      doc["readyState"] != "complete" &&
      doc["readyState"] != "interactive"
    ) {
      return setTimeout(init, 200);
    }
    scriptElem["setAttribute"]("charset", "UTF-8");
    scriptElem["setAttribute"]("src", MAIN_URL + ".js");
    if (BOOKMARK_REDIRECT_STATUS) {
      scriptElem.addEventListener("error", redirectWithError);
    }
    doc.body.appendChild(scriptElem);
  }
  if (typeof scriptElem != "object") {
    redirect("A76DNE9BD1");
  } else {
    init();
  }
})()
`
