import React from 'react'
import { useStore } from '../hooks/useStore'
import { makeStyles } from '@material-ui/styles'
import { useSearch } from '../hooks/useSearch'
import { Badge } from '@material-ui/core'

const useStyle = makeStyles({
  link: {
    cursor: 'pointer',
    fontSize: 18
  }
})

export default function SideMenu({ currentTab, setCurrentTab }) {
  const classes = useStyle()
  const { auth, favorites } = useStore()
  const { facetValues, setSearchObjectType } = useSearch()

  const facetFavorites = favorites.map(domain => `domain:${domain}`)

  function searchFacets(facet) {
    setCurrentTab(facet)
    if (facet === 'comment') {
      setSearchObjectType(['objectType:comment'])
    }
    if (facet === 'sites') {
      setSearchObjectType(['objectType:site'])
    }
    if (facet === 'favorites') {
      setSearchObjectType(facetFavorites)
    }
    if (facet === 'mycomments') {
      setSearchObjectType([`authorId:${auth.uid}`])
    }
  }

  return (
    <div
      className="side-menu"
      style={{
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '1px',
        marginTop: '3em'
      }}>
      <h3 style={{ fontSize: 24 }}>Global Feed</h3>
      <Badge badgeContent={facetValues.comment} color="secondary">
        <p
          className={classes.link}
          style={{ fontWeight: currentTab === 'comment' && 'bold' }}
          onClick={() => searchFacets('comment')}>
          Comments
        </p>
      </Badge>
      <Badge badgeContent={facetValues.site} color="secondary">
        <p
          className={classes.link}
          style={{ fontWeight: currentTab === 'sites' && 'bold' }}
          onClick={() => searchFacets('sites')}>
          Sites
        </p>
      </Badge>
      {auth ? (
        <>
          <h3 style={{ marginTop: 25 }}>Personal Feed</h3>
          <p
            className={classes.link}
            style={{ fontWeight: currentTab === 'favorites' && 'bold' }}
            onClick={() => searchFacets('favorites')}>
            Favorites
          </p>
          <p
            className={classes.link}
            style={{ fontWeight: currentTab === 'mycomments' && 'bold' }}
            onClick={() => searchFacets('mycomments')}>
            My Comments
          </p>
        </>
      ) : null}
    </div>
  )
}
