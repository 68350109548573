import firebase from 'firebase'

const GOOGLE_AUTH_PROVIDER = new firebase.auth.GoogleAuthProvider()

export const GOOGLE_AUTH_CONTROLLER = 'GOOGLE_AUTH_CONTROLLER'

export const loginHandler = type => {
  switch (type) {
    case GOOGLE_AUTH_CONTROLLER:
      return firebase
        .auth()
        .signInWithPopup(GOOGLE_AUTH_PROVIDER)
        .then(res => {
          firebase
            .firestore()
            .collection('users')
            .doc(res.user.uid)
            .set({
              email: res.user.email,
            })
          firebase
            .firestore()
            .collection('profiles')
            .doc(res.user.uid)
            .set({
              name: res.user.displayName,
              picture: res.user.photoURL,
            })
        })
        .catch(err => {
          console.log(err)
        })
    default:
      return firebase.auth().signOut()
  }
}
