import React from 'react'
import { Card, CardMedia, CardContent, Typography } from '@material-ui/core'
import moment from 'moment'
import firebase from 'firebase'
import UserAvatar, { UserName } from './Avatar'
import { makeStyles } from '@material-ui/styles'
import PlaceHolderImage from '../assets/placeholder.svg'
import { useStore } from '../hooks/useStore'

const useStyles = makeStyles({
  card: {
    border: '1px solid rgba(37, 48, 43, 0.4)',
    boxShadow: 'none',
  },
  cardContent: {
    display: 'flex',
  },
})

export default function SiteCard({ name, domain, image, ...rest }) {
  const { getSiteCache, siteCache } = useStore()
  const classes = useStyles()

  let site = null
  if (domain) {
    getSiteCache(domain)
    site = siteCache[domain]
  }

  return (
    <a
      target="_blank"
      href={`https://${domain}`}
      style={{ textDecoration: 'none' }}>
      <Card className={classes.card}>
        <CardMedia
          style={{ height: 250, backgroundPosition: 'top center' }}
          image={site && site.url ? site.url : image}
          title={domain}
        />
        <CardContent style={{ borderTop: '1px solid rgba(37, 48, 43, 0.4)' }}>
          <div className={classes.cardContent}>
            <div style={{ marginRight: 10 }}>
              {/* <UserAvatar uid={authorId} /> */}
            </div>
            <div>
              {/* <UserName uid={authorId} location={location} /> */}
              <Typography
                style={{ color: 'rgba(37, 48, 43, .8)' }}
                variant="subtitle1">
                {name}
              </Typography>
              <Typography
                style={{ color: 'rgba(37, 48, 43, .4)' }}
                variant="caption">
                <a
                  style={{
                    textDecoration: 'none',
                    color: 'rgba(37, 48, 43, .6)',
                  }}
                  href={`https://${domain}`}
                  target="_blank">
                  {domain}
                </a>
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
    </a>
  )
}
